import { FC } from "react";
import { Grid } from "@mui/material";

import { PageHeader } from "@gemunion/mui-page-layout";

import { NativeSection } from "./hierarchy/native";
import { Erc20Section } from "./hierarchy/erc20";
import { VestingSection } from "./mechanics/vesting";

import { Root } from "./styled";

export const Dashboard: FC = () => {
  return (
    <Root>
      <PageHeader message="pages.dashboard.title" />

      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <NativeSection />
          <Erc20Section />
        </Grid>
        <Grid item xs={12} md={4}>
          <VestingSection />
        </Grid>
      </Grid>
    </Root>
  );
};
